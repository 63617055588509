import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

 const firebaseConfig = {
  apiKey: "AIzaSyA3yNv4gxaszSzSlS5xp5G7vAkvf4j_WUo",
  authDomain: "ganasec-4f25c.firebaseapp.com",
  databaseURL: "https://ganasec-4f25c-default-rtdb.firebaseio.com",
  projectId: "ganasec-4f25c",
  storageBucket: "ganasec-4f25c.appspot.com",
  messagingSenderId: "117849802735",
  appId: "1:117849802735:web:ad1c6a599171fc5822d81a"
};
  
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  
  export { app, db };